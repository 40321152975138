import { useState } from "react";

function Search(props) {
    const [search, setSearch] = useState("");

    const handleSearch = (e) => {
        const value = e.target.value;
        if (value.includes('&')) {
            return;
        }
        setSearch(value);
        props.onToggle(value);
    };

    return (
        <div className='w-full flex flex-row items-center justify-end mb-3'>
            <p className='font-bold text-sm text-slate-600'>
                Ara:
            </p>
            <input
                type='text'
                name='search'
                value={search}
                className='bg-transparent border border-gray-400 p-1 ml-1 rounded text-sm outline-none'
                onChange={handleSearch} />
        </div>
    )
}

export default Search;