import React, { useState } from 'react';
import InfoBox from '../components/InfoBox';
import { postInsertAuthority } from '../api/request';
import Title from '../components/Title';
import AuthorityForm from '../components/AuthorityForm';
import { useDispatch } from 'react-redux';

function AddAuthority() {
    document.title = 'Yeni Yetki';

    const [content, setContent] = useState();
    
    const dispatch = useDispatch();

    const handleFormToggle = async (authority) => {
        if (authority.userID === "" || authority.gatewayID === "") {
            setContent("Lütfen tüm alanları doldurun.");
            setTimeout(() => {
                setContent(null);
            }, 2000);
            return;
        }
        try {
            const res = await postInsertAuthority(authority);
            setContent(res.data.content);
            dispatch({ type: 'NAVBARUPDATE', value: true });
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Yeni Yetki" />
                </div>
                <div className='p-3'>
                    <div className='w-full bg-white p-4 mb-4 shadow-md rounded-md'>
                        <AuthorityForm
                            buttonTitle="Kaydet"
                            onToggle={handleFormToggle}
                            authority={{
                                userFullName: "",
                                companyName: ""
                            }} />
                    </div>
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default AddAuthority;