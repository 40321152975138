import { useDispatch } from 'react-redux';
import Title from '../components/Title';
import { useEffect } from 'react';

const Home = () => {
    document.title = 'Anasayfa';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: "Anasayfa" });
    }, [dispatch]);
    
    return (
        <div className={`w-full h-screen flex flex-col`}>
            <div className="sticky top-0 bg-gray-100 z-30">
                <Title title="Anasayfa" />
            </div>
            <div className='p-3 w-full h-full'>
                <div className='w-full h-full bg-no-repeat bg-cover bg-bg-12 shadow-md overflow-auto rounded-md'>
                    <p className='font-medium text-4xl pt-10 pl-8 text-sky-900'>Where Science Meets Industry</p>
                </div>
            </div>
        </div>
    )
}

export default Home;