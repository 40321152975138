import React, { useEffect, useState } from 'react';
import { getAllCompanies } from '../api/request';
import DropdownMenu from './DropdownMenu';

function GatewayForm(props) {
    const [formData, setFormData] = useState(props.gateway);
    const [companiesName, setCompaniesName] = useState(null);
    const [companiesID, setCompaniesID] = useState(null);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onToggle(formData);
    };

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                const res = await getAllCompanies();
                const companiesName = res.data.companies.map(item => item.name);
                const companiesID = res.data.companies.map(item => item._id);
                setCompaniesName(companiesName);
                setCompaniesID(companiesID);
            } catch (error) {
                console.log(error);
            }
        };
        fetchCompany();
    }, []);

    const selectedIndexNoCompany = (index) => {
        setFormData({ ...formData, companyID: companiesID[index] });
    };

    const handleIsActiveToggle = (newIsActive) => {
        setFormData({ ...formData, isActive: newIsActive });
    };

    return (
        <form
            onSubmit={handleSubmit}
            className='w-full flex flex-col md:flex-row'>
            <div className='w-full md:w-80 mr-0 md:mr-5'>
                <div className='flex flex-col mb-6'>
                    <label className='text-slate-600 font-medium mb-1'>Ad</label>
                    <input
                        type="text"
                        name="name"
                        className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                        onChange={handleChange}
                        value={formData.name}
                        required />
                </div>
                <div className='flex flex-col mb-6'>
                    <label className='text-slate-600 font-medium mb-1'>Firmalar</label>
                    {
                        companiesName
                            ? <DropdownMenu
                                selection={formData.companyName}
                                selectedIndexNo={selectedIndexNoCompany}
                                options={companiesName} />
                            : ""
                    }
                </div>
                <div className='flex flex-col mb-6'>
                    <label className='text-slate-600 font-medium mb-1'>Aktif</label>
                    {
                        companiesName
                            ? <DropdownMenu
                                selection={formData.isActive}
                                onToggle={handleIsActiveToggle}
                                options={["Evet", "Hayır"]} />
                            : ""
                    }
                </div>
                <button
                    type="submit"
                    className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500'>
                    {props.buttonTitle}
                </button>
            </div>
        </form>
    )
}

export default GatewayForm;