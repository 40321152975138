import React, { useState } from 'react';
import DropdownMenu from '../components/DropdownMenu';

function CompanyForm(props) {
    const [formData, setFormData] = useState(props.company);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.onToggle(formData);
    };

    const handleIsActiveToggle = (newIsActive) => {
        setFormData({ ...formData, isActive: newIsActive });
    };

    return (
        <form
            onSubmit={handleSubmit}
            className='w-full flex flex-col'>
            <div className='w-full flex flex-col md:flex-row'>
                <div className='w-full md:w-80 mr-0 md:mr-5'>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Firma adı</label>
                        <input
                            type="text"
                            name="name"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.name}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Aktif</label>
                        <DropdownMenu
                            selection={formData.isActive}
                            onToggle={handleIsActiveToggle}
                            options={["Evet", "Hayır"]} />
                    </div>
                </div>
            </div>

            <button
                type="submit"
                className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500 md:w-min'>
                {props.buttonTitle}
            </button>
        </form>
    )
}

export default CompanyForm;