import { useState } from 'react';
import tekbak from '../images/tek-bak.png';
import ViosynIot from '../images/viosyn-iot.png';
import { postResetPassword } from '../api/request';

function ResetPassword() {
    const [content, setContent] = useState();
    const [formData, setFormData] = useState({
        email: "",
        username: ""
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await postResetPassword(formData);
            setContent(res.data.content);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="bg-no-repeat bg-cover w-full h-screen bg-bg-mini min-[575px]:bg-bg">
            <div className='w-full h-screen flex justify-center items-center flex-col'>
                <img className='h-20 m-3' src={ViosynIot} alt="Viosyn IoT" />
                <form
                    onSubmit={handleSubmit}
                    className='flex justify-center items-center flex-col'>
                    <input
                        type="text"
                        name="email"
                        className='w-96 h-12 border border-gray-300 shadow-md rounded-full px-7 py-4 m-2
            text-sm text-left text-gray-700 outline-none backdrop-blur-sm bg-white/30'
                        placeholder='E posta'
                        onChange={handleChange}
                        value={formData.email}
                        required
                    />
                    <input
                        type="text"
                        name="username"
                        className='w-96 h-12 border border-gray-300 shadow-md rounded-full px-7 py-4 m-2 
            text-sm text-left text-gray-700 outline-none backdrop-blur-sm bg-white/30'
                        placeholder='Kullanıcı adı'
                        onChange={handleChange}
                        value={formData.username}
                        required
                    />
                    {content
                        ? <p className='text-sm font-medium text-red-500 mt-2'> {content} </p>
                        : ""
                    }
                    <button className='w-32 h-10 bg-gray-600 text-white m-5 rounded-full text-sm hover:bg-gray-700'>
                        Şifre Sıfırla
                    </button>
                </form>
            </div>
            <img className='absolute bottom-24 left-4 h-24' src={tekbak} alt="Tek Bak" />
        </div>
    );
}

export default ResetPassword;
