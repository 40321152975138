import React, { useState, useEffect } from 'react';
import { getSelectedData } from '../api/request';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa6";
import Search from './Search';
import DropdownMenu from './DropdownMenu';

function AnalysisData(props) {
    const { portNo } = props;
    const [datas, setDatas] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [search, setSearch] = useState("");
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    const [dateOption2, setDateOption2] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setDatas(null);
            const currentUser = JSON.parse(localStorage.getItem("user"))
            const start = startDateCalculate2();
            const end = endDateCalculate();
            try {
                const res = await getSelectedData(portNo, selectedPageNo, start, end, currentUser._id, search);

                if (!res.data.message) {
                    navigate('/');
                    return;
                }
                setDatas(res.data.data.data);
                setPageData({
                    pageNo: res.data.pageNo,
                    totalDataCount: res.data.totalDataCount,
                    totalPageCount: res.data.totalPageCount,
                    start: res.data.start,
                    limit: res.data.limit
                })
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [selectedPageNo, portNo, dateOption2, navigate, search]);

    useEffect(() => {
        setSelectedPageNo(1);
    }, [props]);

    useEffect(() => {
        setStartDate(startDateCalculate2());
        setEndDate(endDateCalculate());
        // eslint-disable-next-line
    }, [dateOption2]);

    const onToggleSearch = (searchKeyword) => {
        setSearch(searchKeyword);
        setSelectedPageNo(1);
    };

    const handleDownload = async () => {
        try {
            const response =
                await axios.get(`https://www.viosyn.com/api/datas/download-excel/${portNo}?startDate=${startDate}&endDate=${endDate}`, {
                    responseType: 'blob',
                });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Data - VIOSYN.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Hata:', error);
        }
    };

    const startDateCalculate2 = () => {
        var currentDate = new Date();

        switch (dateOption2) {
            case 0:
                return new Date(currentDate.setHours(currentDate.getHours() - 5));
            case 1:
                return new Date(currentDate.setHours(currentDate.getHours() - 24));
            case 2:
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
            case 3:
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);
            case 4:
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 180);
            case 5:
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 360);
            default:
                return new Date(currentDate.setHours(currentDate.getHours() - 24));
        }
    }

    const endDateCalculate = () => {
        return new Date();
    }

    const options2 = ["Son 5 saat", "Son 1 gün", "Son 1 hafta", "Son 1 ay", "Son 6 ay", "Son 1 yıl"];

    const selectedIndexNoDate2 = (index) => {
        setDateOption2(index);
    };

    return (
        <div className={`w-full flex flex-col`}>
            {datas
                ? <div className='w-full h-14 flex justify-between bg-white mb-4 shadow-md rounded-md items-center py-2 px-5'>
                    <p className='font-medium'>{props.gatewayName}</p>

                    <div className='w-36'>
                        <DropdownMenu
                            selection={options2[dateOption2]}
                            selectedIndexNo={selectedIndexNoDate2}
                            options={options2} />
                    </div>

                </div>
                : ""
            }
            {datas
                ?
                <div>
                    <div className='flex flex-row mb-4'>
                        <div className='w-min bg-blue-500 text-white rounded-sm hover:bg-blue-600 pl-2 pr-3 py-2
                                        flex flex-row items-center mr-3 cursor-pointer text-sm'
                            onClick={() => {
                                props.onToggle(true);
                            }}>
                            <span className={`text-sm mr-2 content-center rotate-90`}>
                                <FaAngleDown className={`w-4 h-4 `} />
                            </span>
                            Grafik
                        </div>
                        <div className='w-min bg-blue-500 text-white rounded-sm hover:bg-blue-600 px-3 py-2
                                        flex flex-row items-center cursor-pointer text-sm'
                            onClick={handleDownload}>
                            Excel
                        </div>
                        <Search onToggle={onToggleSearch} />
                    </div>

                    <div className='overflow-auto'>
                        <table className="min-w-full shadow-md text-center text-sm font-light">
                            <thead
                                className="border-b bg-neutral-50 text-slate-600 font-medium">
                                <tr>
                                    <th scope="col" className=" px-6 py-4 text-left w-3/5">Tarih</th>
                                    <th scope="col" className=" px-6 py-4 text-left w-2/5">Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datas.map((row, index) => (
                                    <tr className={`border-b ${index % 2 === 0 ? "bg-white" : "bg-zinc-100"} hover:bg-slate-100`} key={index}>

                                        <td className="px-6 py-4 text-left">{row.date}</td>
                                        <td className="px-6 py-4 text-left">{row.value}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {pageData && pageData?.totalDataCount > 0
                        ?
                        <div className='w-full mb-3 flex flex-row justify-between items-center pt-3 border-t border-slate-950'>
                            <div>
                                <p className="text-sm">
                                    {pageData.totalDataCount} kayıttan {pageData.start + 1} - {pageData.start + datas.length} arasındaki kayıtlar gösteriliyor
                                </p>
                            </div>
                            <div>
                                {
                                    <button
                                        className={`w-8 h-8 m-1 
                                        hover:bg-zinc-200 
                                        text-sm`}
                                        onClick={() => { setSelectedPageNo(1) }}>
                                        İlk
                                    </button>
                                }

                                {
                                    pageData.pageNo - 1 !== 0
                                        ?
                                        <button
                                            className={`w-8 h-8 m-1 
                                        ${pageData.pageNo === pageData.pageNo - 1 ? "bg-zinc-50 border border-slate-400 shadow-sm" : ""}  
                                        hover:bg-zinc-200 
                                        text-sm`}
                                            onClick={() => { setSelectedPageNo(pageData.pageNo - 1) }}>
                                            {Number(pageData.pageNo) - 1}
                                        </button>

                                        : ""
                                }

                                {
                                    <button
                                        className={`w-8 h-8 m-1 
                                        bg-zinc-50 border border-slate-400 shadow-sm
                                        hover:bg-zinc-200 
                                        text-sm`}
                                        onClick={() => { setSelectedPageNo(pageData.pageNo) }}>
                                        {pageData.pageNo}
                                    </button>
                                }

                                {
                                    Number(pageData.pageNo) + 1 !== pageData.totalPageCount + 1
                                        ?
                                        <button
                                            className={`w-8 h-8 m-1 
                                        ${pageData.pageNo === Number(pageData.pageNo) + 1 ? "bg-zinc-50 border border-slate-400 shadow-sm" : ""}  
                                        hover:bg-zinc-200 
                                        text-sm`}
                                            onClick={() => { setSelectedPageNo(Number(pageData.pageNo) + 1) }}>
                                            {Number(pageData.pageNo) + 1}
                                        </button>
                                        : ""
                                }

                                {
                                    <button
                                        className={`w-8 h-8 m-1 
                                        hover:bg-zinc-200 
                                        text-sm`}
                                        onClick={() => { setSelectedPageNo(pageData.totalPageCount) }}>
                                        Son
                                    </button>
                                }

                            </div>
                        </div>
                        : ""
                    }
                </div>
                : <div className='w-full h-14 flex justify-between bg-white mb-5 shadow-md rounded-md items-center py-2 px-5'>
                    <p className='font-medium'>Veriler Yükleniyor</p>
                </div>

            }
        </div>
    )
}

export default AnalysisData;