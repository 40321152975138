import React, { useState } from 'react';
import InfoBox from '../components/InfoBox';
import { postInsertGateway } from '../api/request';
import Title from '../components/Title';
import GatewayForm from '../components/GatewayForm';
import { useDispatch } from 'react-redux';

function AddGateway() {
    document.title = 'Yeni Gateway';

    const [content, setContent] = useState();
    const dispatch = useDispatch();

    const handleFormToggle = async (newGateway) => {
        if (newGateway.companyID === "") {
            setContent("Lütfen tüm alanları doldurun.");
            setTimeout(() => {
                setContent(null);
            }, 2000);
            return;
        }
        try {
            const res = await postInsertGateway(newGateway);
            setContent(res.data.content);
            dispatch({ type: 'NAVBARUPDATE', value: true });
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Yeni Gateway" />
                </div>
                <div className='p-3'>
                    <div className='w-full bg-white p-4 mb-4 shadow-md rounded-md'>
                        <GatewayForm
                            buttonTitle="Kaydet"
                            onToggle={handleFormToggle}
                            gateway={{
                                name: "",
                                companyID: "",
                                isActive: "Evet"
                            }} />
                    </div>
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default AddGateway;