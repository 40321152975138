import React, { useState } from 'react'
import { FaShield, FaAngleDown } from "react-icons/fa6";
import { PiBuildingsFill  } from "react-icons/pi";
import { Link } from 'react-router-dom';

const Dropdown = props => {
    const activePage = props.activePage;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div>
            <div className={`h-8 w-full flex rounded-md p-2 py-5 cursor-pointer hover:bg-light-white
                            text-gray-300 text-sm items-center gap-x-4 hover:bg-blue-50 hover:text-slate-900 group
                            rounded-md menu-items ${isDropdownOpen && props.open ? 'bg-blue-50' : ''}`}
                onClick={toggleDropdown}>
                <div>
                    {
                        props.logo === 0
                            ? <FaShield className={`w-4 h-4 ${isDropdownOpen && props.open ? 'text-blue-400' : 'text-slate-400 '} group-hover:text-blue-400`} />
                            : <PiBuildingsFill className={`w-4 h-4 ${isDropdownOpen && props.open ? 'text-blue-400' : 'text-slate-400 '} group-hover:text-blue-400`} />
                    }
                </div>
                <div className={`${!props.open && "hidden"} flex justify-between w-full`}>
                    <span className={`group-hover:text-blue-400 ${isDropdownOpen && props.open ? 'text-blue-400' : 'text-slate-500 '}`}>
                        {props.title}
                    </span>
                    <span className={`text-sm h-4 content-center ${isDropdownOpen ? 'rotate-0' : '-rotate-90'}`}>
                        <FaAngleDown className={`w-4 h-4 group-hover:text-blue-400 ${isDropdownOpen && props.open ? 'text-blue-400' : 'text-slate-400 '}`} />
                    </span>
                </div>
            </div>
            <div className={`transition-all duration-500 bg-zinc-50 text-left text-sm mx-auto text-gray-300 whitespace-nowrap
                overflow-hidden max-w-full transition-all ${isDropdownOpen && props.open ? "max-h-96" : "max-h-0"}`}>
                {props.list.map((item, index) => (
                    <Link to={item.link} key={index}>
                        <h1 className={`cursor-pointer p-2 pl-8 hover:bg-blue-50 hover:text-blue-400 rounded-md
                        ${activePage === item.title ? "bg-blue-50 text-blue-400" : "bg-white text-slate-500"}`} key={index}>{item.title}</h1>
                    </Link>
                ))}
            </div>

        </div>
    );
}

export default Dropdown