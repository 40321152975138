import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const Title = props => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const user = localStorage.getItem("user");
        setCurrentUser(JSON.parse(user));
        // eslint-disable-next-line
    }, [localStorage.getItem("user")]);

    const turkishToUpper = (str) => {
        const turkishMap = {
            'i': 'İ',
            'ı': 'I',
            'ğ': 'Ğ',
            'ü': 'Ü',
            'ş': 'Ş',
            'ö': 'Ö',
            'ç': 'Ç',
        };
        return str ? str.replace(/[iğıüşöç]/g, match => turkishMap[match]) : str;
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const logout = () => {
        localStorage.removeItem('viosyn');
        localStorage.removeItem('user');
        navigate('/login');
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
    }, []);

    return (
        <div>
            {currentUser
                ?
                <>
                    <div className="w-full h-16 relative inline-block text-left" ref={dropdownRef}>
                        <div className="w-auto flex flex-row justify-end px-7 py-4">
                            <div className='cursor-pointer flex flex-row items-center'
                            onClick={toggleDropdown}>
                                <div className="mr-4 max-sm:hidden">
                                    <span className="font-medium text-sm text-slate-400">Merhaba, </span>
                                    <span className="font-bold text-sm text-slate-500">
                                        {
                                            turkishToUpper(currentUser.name).toUpperCase()
                                            + " " +
                                            turkishToUpper(currentUser.surname).toUpperCase()
                                        }
                                    </span>
                                </div>
                                <div className="w-min bg-slate-950 px-3 py-1 text-white font-bold rounded-md">
                                    {
                                        turkishToUpper(currentUser.name).toUpperCase().charAt(0)
                                    }
                                </div>
                            </div>
                        </div>
                        {isOpen && (
                            <div
                                className="w-48 absolute right-0 mt-0 mr-4 space-y-2 origin-top-right bg-white border border-gray-300 
                        rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50 max-h-52 overflow-y-auto"
                                role="menu"
                                aria-orientation="vertical"
                                aria-labelledby="options-menu"
                            >
                                <button
                                    onClick={() => logout()}
                                    className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    role="menuitem"
                                >
                                    Çıkış
                                </button>

                            </div>
                        )}
                    </div>
                    <div className='w-full h-14 bg-white px-6 py-4 font-medium shadow-md'>
                        <h1>{props.title}</h1>
                    </div>
                </>
                : ""
            }
        </div>
    )
}

export default Title