import React, { useEffect, useRef, useState } from 'react';
import ViosynLogo from '../images/viosyn-logo.png';
import { FaAngleLeft, FaUserLarge, FaAngleRight } from 'react-icons/fa6';
import Dropdown from './Dropdown';
import { Link } from 'react-router-dom';
import { getUserCompanies } from '../api/request';
import { useDispatch, useSelector } from 'react-redux';

function NavBar() {
    const [open, setOpen] = useState(true);
    const [currentUser, setCurrentUser] = useState();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [userCompanies, setUserCompanies] = useState();
    const navbarRef = useRef(null);
    const lgScreenWidth = 1024;

    const isNavbarUpdate = useSelector((state) => state.isNavbarUpdate);
    const currentPage = useSelector((state) => state.currentPage) || "";
    const dispatch = useDispatch();
    const handleOnClick = () => {
        setOpen((prevState) => !prevState);
    };

    useEffect(() => {
        const user = localStorage.getItem('user');
        setCurrentUser(JSON.parse(user));

        const fetchCompanies = async () => {
            try {
                const res = await getUserCompanies(JSON.parse(user)._id);
                setUserCompanies(res.data);
            } catch (error) {
                console.log(error);
            }
        }
        fetchCompanies();
    }, []);

    useEffect(() => {
        const user = localStorage.getItem('user');
        setCurrentUser(JSON.parse(user));

        const fetchCompanies = async () => {
            try {
                const res = await getUserCompanies(JSON.parse(user)._id);
                setUserCompanies(res.data);
            } catch (error) {
                console.log(error);
            }
        }

        if (isNavbarUpdate) {
            fetchCompanies();
            dispatch({ type: 'NAVBARUPDATE', value: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNavbarUpdate]);

    useEffect(() => {
        setOpen(screenWidth > lgScreenWidth)
    }, [screenWidth]);

    const handleOutsideClick = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target) && window.innerWidth < lgScreenWidth) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='w-min h-full shadow-xl' ref={navbarRef}>
            <div className={`${open ? 'w-64' : 'w-16'} h-full p-4 pb-20 bg-white`}>
                <div className='w-full flex justify-between items-center'>
                    <div>
                        <Link to='/'>
                            <div className='flex gap-x-4 flex justify-center items-center'>
                                <img src={ViosynLogo}
                                    alt='vision-logo'
                                    className={`${open ? 'w-20' : 'w-0'} h-10 cursor-pointer`}
                                />
                            </div>
                        </Link>
                    </div>
                    <div>
                        {open
                            ? <FaAngleLeft onClick={handleOnClick}
                                className='cursor-pointer rounded-full w-8 h-8 p-1 text-gray-300
                                    hover:text-blue-400 transition duration-150 ease-in-out' />
                            : <FaAngleRight onClick={handleOnClick}
                                className='cursor-pointer rounded-full w-8 h-8 p-1 text-gray-300
                                    hover:text-blue-400 transition duration-150 ease-in-out' />
                        }
                    </div>
                </div>
                <div className={`mt-6 menu ${open && "overflow-auto"}  h-full`}>
                    <Link to='/profile'>
                        <div
                            className={`h-8 flex rounded-md p-2 py-5 cursor-pointer hover:bg-light-white text-gray-300 group
                                    text-sm items-center gap-x-4 hover:bg-blue-50 hover:text-slate-900 rounded-md menu-items
                                    ${currentPage === "Profil" ? "bg-blue-50 text-slate-900" : ""}`}>
                            <FaUserLarge className={`w-4 h-4 group-hover:text-blue-400
                                    ${currentPage === "Profil" ? "text-blue-400" : "text-slate-400 "}`} />
                            <span className={`${!open && "hidden"} group-hover:text-blue-400
                            ${currentPage === "Profil" ? "text-blue-400" : "text-slate-500"}`}>
                                Profil
                            </span>
                        </div>
                    </Link>

                    {currentUser && currentUser.role === "Admin"
                        ?
                        <Dropdown
                            open={open}
                            title={"Yönetici Paneli"}
                            activePage={currentPage}
                            logo={0}
                            list={
                                [{
                                    "title": "Kullanıcılar",
                                    "link": "/users"
                                },
                                {
                                    "title": "Gateway Listesi",
                                    "link": "/gateways"
                                },
                                {
                                    "title": "Port Listesi",
                                    "link": "/ports"
                                },
                                {
                                    "title": "Yetki Listesi",
                                    "link": "/authorities"
                                },
                                {
                                    "title": "Firmalar",
                                    "link": "/companies"
                                }]
                            }
                        />
                        : ""
                    }

                    {userCompanies
                        ?
                        userCompanies.map((userCompany, index) => (
                            <Dropdown
                                open={open}
                                logo={1}
                                key={index}
                                title={userCompany.companyName}
                                activePage={currentPage}
                                list={
                                    userCompany.gateways.map(gateway => (
                                        {
                                            "title": gateway.gatewayName,
                                            "link": `/analysis/${gateway.gatewayID}`
                                        }
                                    ))
                                }
                            />
                        ))
                        : ""
                    }
                </div>
            </div>
        </div>
    )
}

export default NavBar;