import React, { useEffect, useState } from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, LineChart } from 'recharts';
import { FaDownload, FaLayerGroup, FaGear } from "react-icons/fa6";
import html2canvas from 'html2canvas';

function AnalysisGraphic(props) {
    const [datas, setDatas] = useState(props.data);

    useEffect(() => {
        setDatas(props.data);
    }, [props.data]);

    const downloadPNG = (index, title) => {
        const elements = document.querySelectorAll('#downloadContent');
        const element = elements[index];
        html2canvas(element).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imgData;
            link.download = title + ".png";
            link.click();
        });
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload.length !== 0) {
            return (
                <div className="flex flex-col p-3 border border-gray-300 bg-white">
                    <div className='flex flex-row'>
                        <p className='font-medium'>{`${label}: `}</p>
                        <span className='ml-1'>{`${payload[0].payload.value}`}</span>
                    </div>
                    {
                        payload[0].payload.topLimitYellowLevel || payload[0].payload.topLimitRedLevel
                            ?
                            <div className='flex flex-row'>
                                <p className='font-medium'>Üst limit: </p>
                                {payload[0].payload.topLimitYellowLevel ? <span className='mx-1'>{`${payload[0].payload.topLimitYellowLevel}`}</span> : ""}
                                {payload[0].payload.topLimitRedLevel ? <span className='mx-1'>{`${payload[0].payload.topLimitRedLevel}`}</span> : ""}
                            </div>
                            : ""
                    }
                    {
                        payload[0].payload.downLimitYellowLevel || payload[0].payload.downLimitYellowLevel
                            ?
                            <div className='flex flex-row'>
                                <p className='font-medium'>Alt limit: </p>
                                {payload[0].payload.downLimitYellowLevel ? <span className='mx-1'>{`${payload[0].payload.downLimitYellowLevel}`}</span> : ""}
                                {payload[0].payload.downLimitRedLevel ? <span className='mx-1'>{`${payload[0].payload.downLimitRedLevel}`}</span> : ""}
                            </div>
                            : ""
                    }
                </div>
            );
        }

        return null;
    };

    return (
        <div className={`w-full flex flex-col`}>

            {datas.map((data, index) => (
                <div className='w-full py-4 pr-2 mb-4 bg-white shadow-md rounded-md' key={index}>
                    <div className='flex flex-row ml-5 mb-3'>
                        <a href={`/analysis/port-setting/${data.portNo}`}>
                            <div className='bg-blue-400 text-white rounded-lg hover:bg-blue-500 px-3 py-2
                                                flex flex-row items-center mr-1 cursor-pointer text-sm'>
                                <FaGear className='mr-2' />
                                Ayarlar
                            </div>
                        </a>
                        <div className='bg-blue-400 text-white rounded-lg hover:bg-blue-500 px-3 py-2
                                             flex flex-row items-center mr-1 cursor-pointer text-sm'
                            onClick={() => {
                                props.onToggle(false);
                                props.onPortNo(data.portNo);
                            }}>
                            <FaLayerGroup className='mr-2' />
                            Data
                        </div>
                        <div className='bg-blue-400 text-white rounded-lg hover:bg-blue-500 px-3 py-2
                                             flex flex-row items-center mr-1 cursor-pointer text-sm'
                            onClick={() => {
                                const title = data.portTypeName + " - Port " + data.portNo;
                                downloadPNG(index, title);
                            }}>
                            <FaDownload className='mr-2' />
                            PNG
                        </div>
                    </div>
                    <div id="downloadContent">
                        <p className='text-slate-800 flex justify-center text-lg font-medium'>{data.portTypeName} - Port {data.portNo}</p>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={data.data} margin={{ top: 20, right: 20, left: 0, bottom: 0 }}>
                                <XAxis dataKey="date" tick={{ fontSize: 14 }} />
                                {/* <YAxis tick={{ fontSize: 14 }} /> */}
                                <YAxis tick={{ fontSize: 14 }} domain={["auto", "auto"]} />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip content={<CustomTooltip />} />
                                <Line type="linear" dataKey="value" stroke="#82ca9d" dot={false} />
                                <Line type="linear" dataKey="topLimitYellowLevel" stroke="#F4D03F" dot={false} />
                                <Line type="linear" dataKey="topLimitRedLevel" stroke="#C0392B" dot={false} />
                                <Line type="linear" dataKey="downLimitYellowLevel" stroke="#F4D03F" dot={false} />
                                <Line type="linear" dataKey="downLimitRedLevel" stroke="#C0392B" dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                </div>
            ))}
        </div>
    )
}

export default AnalysisGraphic;