import { makeRequest } from "./utils"

export const getAllUsers = () => {
    return makeRequest("get", "users/all-users");
}

export const getFindUser = (userID) => {
    return makeRequest("get", `users/find-user/${userID}`);
}

export const postInsertUser = (user) => {
    return makeRequest("post", "users/insert-user", user);
}

export const postUpdateUser = (user) => {
    return makeRequest("post", "users/update-user", user);
}

export const getAllGateways = () => {
    return makeRequest("get", "gateways/all-gateways");
}

export const getFindGateway = (gatewayID) => {
    return makeRequest("get", `gateways/find-gateway/${gatewayID}`);
}

export const postInsertGateway = (gateway) => {
    return makeRequest("post", "gateways/insert-gateway", gateway);
}

export const postUpdateGateway = (gateway) => {
    return makeRequest("post", "gateways/update-gateway", gateway);
}

export const getAllPortTypes = () => {
    return makeRequest("get", "port-types/all-port-types");
}

export const getAllPorts = (selectedPageNo, search) => {
    return makeRequest("get", `ports/all-ports?pageNo=${selectedPageNo}&search=${search}`);
}

export const deletePort = (portID) => {
    return makeRequest("delete", `ports/delete-port/${portID}`);
}

export const postInsertPort = (port) => {
    return makeRequest("post", "ports/insert-port", port);
}

export const getSelectedCompanies = (selectedPageNo, search) => {
    return makeRequest("get", `companies/selected-companies?pageNo=${selectedPageNo}&search=${search}`);
}

export const getSelectedUsers = (selectedPageNo, search) => {
    return makeRequest("get", `users/selected-users?pageNo=${selectedPageNo}&search=${search}`);
}

export const getSelectedGateways = (selectedPageNo, search) => {
    return makeRequest("get", `gateways/selected-gateways?pageNo=${selectedPageNo}&search=${search}`);
}

export const getSelectedAuthorities = (selectedPageNo, search) => {
    return makeRequest("get", `authorities/selected-authorities?pageNo=${selectedPageNo}&search=${search}`);
}

export const getSelectedData = (portNo, selectedPageNo, startDate, endDate, userID, search) => {
    return makeRequest("get", `datas/selected-datas/${portNo}?pageNo=${selectedPageNo}&startDate=${startDate}&endDate=${endDate}&userID=${userID}&search=${search}`);
}

export const postLogin = (user) => {
    return makeRequest("post", "login", user);
}

export const postResetPassword = (user) => {
    return makeRequest("post", "/reset-password", user);
}

export const getAllAuthorities = () => {
    return makeRequest("get", "authorities/all-authorities");
}

export const postInsertAuthority = (authority) => {
    return makeRequest("post", "authorities/insert-authority", authority);
}

export const deleteAuthorities = (authorityID) => {
    return makeRequest("delete", `authorities/delete-authority/${authorityID}`);
}

export const getFindAuthority = (authorityID) => {
    return makeRequest("get", `authorities/find-authority/${authorityID}`);
}

export const postUpdateAuthority = (authority) => {
    return makeRequest("post", "authorities/update-authority", authority);
}

export const getIsEmailAvailable = (email) => {
    return makeRequest("get", `users/is-email-available/${email}`);
}

export const getIsUsernameAvailable = (username) => {
    return makeRequest("get", `users/is-username-available/${username}`);
}

export const postInsertCompany = (company) => {
    return makeRequest("post", "companies/insert-company", company);
}

export const getAllCompanies = () => {
    return makeRequest("get", "companies/all-companies");
}

export const postUpdateCompany = (company) => {
    return makeRequest("post", "companies/update-company", company);
}

export const getFindCompany = (companyID) => {
    return makeRequest("get", `companies/find-company/${companyID}`);
}

export const getUserCompanies = (userID) => {
    return makeRequest("get", `users/user-companies/${userID}`);
}

export const getGatewayDatas = (gatewayID, startDate, endDate, userID) => {
    return makeRequest("get", `datas/gateway-datas/${gatewayID}?startDate=${startDate}&endDate=${endDate}&userID=${userID}`);
}

export const postUpdateUserPassword = (newPassword) => {
    return makeRequest("post", "users/change-password", newPassword);
}

export const postPortSetting = (limit) => {
    return makeRequest("post", "ports/port-setting", limit);
}

export const getSelectedPortSetting = (portNo, userID) => {
    return makeRequest("get", `ports/selected-port-setting?portNo=${portNo}&userID=${userID}`);
}