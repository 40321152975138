import React, { useState, useEffect } from 'react';
import { getSelectedUsers } from '../api/request';
import { FaRegPenToSquare, FaPlus } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import Title from '../components/Title';
import Search from '../components/Search';
import { useDispatch } from 'react-redux';

function Users() {
    document.title = 'Kullanıcı Listesi';
    const [users, setUsers] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [search, setSearch] = useState("");
    const [selectedPageNo, setSelectedPageNo] = useState(1);
    const dispatch = useDispatch();

    const fetchUsers = async () => {
        try {
            const res = await getSelectedUsers(selectedPageNo, search);
            setUsers(res.data.users);
            setPageData({
                pageNo: res.data.pageNo,
                totalUserCount: res.data.totalUserCount,
                totalPageCount: res.data.totalPageCount,
                start: res.data.start,
                limit: res.data.limit
            })
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line
    }, [selectedPageNo, search]);

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: "Kullanıcılar" });
    }, [dispatch]);

    const columns = ['Ad', 'Soyad', 'E-posta', "Rol", "Kullanıcı adı", "Telefon", "Aktif"];

    const onToggleSearch = (searchKeyword) => {
        setSearch(searchKeyword);
        setSelectedPageNo(1);
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="flex flex-col">
                    <div className="sticky top-0 bg-gray-100 z-30">
                        <Title title="Kullanıcılar" />
                    </div>
                    <div className='p-3'>
                        <Search onToggle={onToggleSearch}/>
                        <div className='overflow-auto'>
                            <table className="min-w-full shadow-md text-center text-sm font-light">
                                <thead
                                    className="border-b bg-neutral-50 text-slate-600 font-medium">
                                    <tr>
                                        <th scope="col" className='p-4'>
                                            <Link to='/users/add-user' className='w-max h-max'>
                                                <div className='w-min h-full p-2 rounded-lg border-blue-400
                                                    border-2 text-blue-400
                                                    hover:cursor-pointer 
                                                    hover:bg-blue-300
                                                    hover:text-white'>
                                                    <FaPlus className='w-4 h-4' />
                                                </div>
                                            </Link>
                                        </th>
                                        {columns.map((column, index) => (
                                            <th scope="col" className=" px-6 py-4 text-left" key={index}>{column}</th>
                                        ))}
                                    </tr>
                                </thead>
                                {users
                                    ?
                                    <tbody>
                                        {users.map((row, index) => (
                                            <tr className={`border-b ${index % 2 === 0 ? "bg-white" : "bg-zinc-100"} hover:bg-slate-100`} key={index}>
                                                <td className="w-5 px-6 py-4">
                                                    <a href={`/users/update-user?userID=${row._id}`}>
                                                        <FaRegPenToSquare className='w-4 h-4 hover:cursor-pointer  text-emerald-400 hover:text-emerald-700' />
                                                    </a>
                                                </td>
                                                <td className="px-6 py-4 text-left">{row.name}</td>
                                                <td className="px-6 py-4 text-left">{row.surname}</td>
                                                <td className="px-6 py-4 text-left">{row.email}</td>
                                                <td className="px-6 py-4 text-left">{row.role}</td>
                                                <td className="px-6 py-4 text-left">{row.username}</td>
                                                <td className="px-6 py-4 text-left">{row.phone}</td>
                                                <td className="px-6 py-4 text-left">
                                                    <input
                                                        type="checkbox"
                                                        checked={row.isActive === "Evet"}
                                                        readOnly
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    : ""}
                            </table>
                        </div>
                        {pageData && pageData?.totalUserCount > 0
                            ?
                            <div className='w-full flex flex-row justify-between items-center pt-3 border-t border-slate-950'>
                                <div>
                                    <p className="text-sm">
                                        {pageData.totalUserCount} kayıttan {pageData.start + 1} - {pageData.start + users.length} arasındaki kayıtlar gösteriliyor
                                    </p>
                                </div>
                                <div>
                                    {
                                        <button
                                            className={`w-8 h-8 m-1 
                                        hover:bg-zinc-200 
                                        text-sm`}
                                            onClick={() => { setSelectedPageNo(1) }}>
                                            İlk
                                        </button>
                                    }

                                    {
                                        pageData.pageNo - 1 !== 0
                                            ?
                                            <button
                                                className={`w-8 h-8 m-1 
                                        ${pageData.pageNo === pageData.pageNo - 1 ? "bg-zinc-50 border border-slate-400 shadow-sm" : ""}  
                                        hover:bg-zinc-200 
                                        text-sm`}
                                                onClick={() => { setSelectedPageNo(pageData.pageNo - 1) }}>
                                                {Number(pageData.pageNo) - 1}
                                            </button>

                                            : ""
                                    }

                                    {
                                        <button
                                            className={`w-8 h-8 m-1 
                                        bg-zinc-50 border border-slate-400 shadow-sm
                                        hover:bg-zinc-200 
                                        text-sm`}
                                            onClick={() => { setSelectedPageNo(pageData.pageNo) }}>
                                            {pageData.pageNo}
                                        </button>
                                    }

                                    {
                                        Number(pageData.pageNo) + 1 !== pageData.totalPageCount + 1
                                            ?
                                            <button
                                                className={`w-8 h-8 m-1 
                                        ${pageData.pageNo === Number(pageData.pageNo) + 1 ? "bg-zinc-50 border border-slate-400 shadow-sm" : ""}  
                                        hover:bg-zinc-200 
                                        text-sm`}
                                                onClick={() => { setSelectedPageNo(Number(pageData.pageNo) + 1) }}>
                                                {Number(pageData.pageNo) + 1}
                                            </button>
                                            : ""
                                    }

                                    {
                                        <button
                                            className={`w-8 h-8 m-1 
                                        hover:bg-zinc-200 
                                        text-sm`}
                                            onClick={() => { setSelectedPageNo(pageData.totalPageCount) }}>
                                            Son
                                        </button>
                                    }

                                </div>
                            </div>
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users;