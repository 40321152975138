import React, { useState } from 'react';
import InfoBox from '../components/InfoBox';
import { postInsertUser } from '../api/request';
import UserForm from '../components/UserForm';
import Title from '../components/Title';

function AddUser() {
    document.title = 'Yeni Kullanıcı';

    const [content, setContent] = useState();

    const handleFormToggle = async (user) => {
        try {
            const res = await postInsertUser(user);
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Yeni Kullanıcı" />
                </div>
                <div className='p-3'>
                    <div className='w-full bg-white p-4 mb-4 shadow-md overflow-auto rounded-md'>
                        <UserForm
                            buttonTitle="Kaydet"
                            onToggle={handleFormToggle}
                            user={{
                                name: '',
                                surname: '',
                                username: '',
                                role: 'Admin',
                                email: '',
                                phone: '',
                                isActive: 'Evet'
                            }} />
                        <p className='text-sm my-2'>Şifre e posta adresine gönderilecektir</p>
                    </div>
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default AddUser;