import React, { useEffect, useState } from 'react';
import InfoBox from '../components/InfoBox';
import { getFindGateway, postUpdateGateway } from '../api/request';
import { useLocation } from 'react-router-dom';
import Title from '../components/Title';
import GatewayForm from '../components/GatewayForm';
import { useDispatch } from 'react-redux';

function UpdateGateway() {
    document.title = 'Gateway Güncelle';
    const location = useLocation();
    const gatewayID = new URLSearchParams(location.search).get('gatewayID') || "";

    const [content, setContent] = useState();
    const [formData, setFormData] = useState(null);
    const dispatch = useDispatch();

    const handleFormToggle = async (gateway) => {
        try {
            gateway._id = gatewayID;
            const res = await postUpdateGateway(gateway);
            setContent(res.data.content);
            dispatch({ type: 'NAVBARUPDATE', value: true });
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchGateway = async () => {
            try {
                const res = await getFindGateway(gatewayID);
                setFormData(res.data.gateways);
            } catch (error) {
                console.log(error);
            }
        };
        fetchGateway();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Gateway Güncelle" />
                </div>
                <div className='p-3'>
                    {
                        formData && gatewayID
                            ?
                            <div className='w-full bg-white p-4 mb-4 shadow-md rounded-md'>
                                <GatewayForm
                                    buttonTitle="Güncelle"
                                    onToggle={handleFormToggle}
                                    gateway={formData} />
                            </div>
                            : ""
                    }
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default UpdateGateway;