import React, { useState } from 'react';
import InfoBox from '../components/InfoBox';
import { postInsertCompany } from '../api/request';
import Title from '../components/Title';
import CompanyForm from '../components/CompanyForm';

function AddCompany() {
    document.title = 'Yeni Firma';

    const [content, setContent] = useState();

    const handleFormToggle = async (company) => {
        try {
            const res = await postInsertCompany(company);
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Yeni Firma" />
                </div>
                <div className='p-3'>
                    <div className='w-full bg-white p-4 mb-4 shadow-md rounded-md'>
                        <CompanyForm
                            buttonTitle="Kaydet"
                            onToggle={handleFormToggle}
                            company={{
                                name: "",
                                isActive: 'Evet'
                            }} />
                    </div>
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default AddCompany;