import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
// import { postUpdateUser } from '../api/request';
// import InfoBox from '../components/InfoBox';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function Profile() {
    document.title = 'Profil';

    const [currentUser, setCurrentUser] = useState();
    // const [content, setContent] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const user = localStorage.getItem('user');
        setCurrentUser(JSON.parse(user));
    }, []);

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: "Profil" });
    }, [dispatch]);

    const handleChange = (e) => {
        setCurrentUser({ ...currentUser, [e.target.name]: e.target.value });
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const res = await postUpdateUser(currentUser);
    //         setContent(res.data.content);
    //         localStorage.setItem("user", JSON.stringify(currentUser));
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Profil" />
                </div>
                <div className='p-3'>
                    <div className='w-full bg-white p-4 mb-4 shadow-md overflow-auto rounded-md'>
                        {currentUser
                            ? <form
                                // onSubmit={handleSubmit}
                                className='w-full flex flex-col md:flex-row'>
                                <div className='w-full md:w-80 mr-0 md:mr-5'>
                                    <div className='flex flex-col mb-6'>
                                        <label className='text-slate-600 font-medium mb-1'>Ad</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                            onChange={handleChange}
                                            value={currentUser.name}
                                            required
                                            disabled />
                                    </div>
                                    <div className='flex flex-col mb-6'>
                                        <label className='text-slate-600 font-medium mb-1'>Soyad</label>
                                        <input
                                            name="surname"
                                            type="text"
                                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                            onChange={handleChange}
                                            value={currentUser.surname}
                                            required
                                            disabled />
                                    </div>
                                </div>
                                <div className='w-full md:w-80 mr-0 md:mr-5'>
                                    <div className='flex flex-col mb-6'>
                                        <label className='text-slate-600 font-medium mb-1'>E posta</label>
                                        <input
                                            name="email"
                                            type="text"
                                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                            onChange={handleChange}
                                            value={currentUser.email}
                                            required
                                            disabled />
                                    </div>
                                    <div className='flex flex-col mb-6'>
                                        <label className='text-slate-600 font-medium mb-1'>Cep Telefonu</label>
                                        <input
                                            name="phone"
                                            type="text"
                                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                            onChange={handleChange}
                                            value={currentUser.phone}
                                            required
                                            disabled />
                                    </div>
                                    {/* <button
                                        type="submit"
                                        className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500'>
                                        Güncelle
                                    </button> */}

                                    <Link to={"/profile/change-password"}>
                                        <button
                                            className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500'>
                                            Şifre Değiştirme
                                        </button>
                                    </Link>
                                </div>
                            </form>
                            : ""
                        }
                    </div>
                </div>
            </div>
            {/* {content ? <InfoBox message={content} /> : ""} */}
        </div>
    )
}

export default Profile;