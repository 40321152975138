import { useDispatch } from 'react-redux';
import Title from '../components/Title';
import { useEffect } from 'react';

function Page404() {
    document.title = '404';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: "404" });
    }, [dispatch]);
    

    return (
        <div className={`w-full h-screen flex flex-col`}>
            <div className="sticky top-0 bg-gray-100 z-30">
                <Title title="404" />
            </div>
            <div className='p-3 h-full'>
                <div className='w-full h-full bg-no-repeat items-center flex bg-cover bg-bg-12 shadow-md overflow-auto rounded-md'>
                    <div className='ml-28'>
                        <p className='font-bold text-9xl text-slate-600'>404</p>
                        <p className='font-medium text-xl text-slate-300'>Sayfa Bulunamadı</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page404;