import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import { postUpdateUserPassword } from '../api/request';
import InfoBox from '../components/InfoBox';

function ChangePassword() {
    document.title = 'Profil';

    const [currentUser, setCurrentUser] = useState();
    const [content, setContent] = useState();
    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        newPasswordRepeat: ""
    });

    useEffect(() => {
        const user = localStorage.getItem('user');
        setCurrentUser(JSON.parse(user));
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.newPassword.length < 8) {
            setContent("Yeni şifre en az 8 karakterden oluşmalı");
            setTimeout(() => {
                setContent(null);
            }, 2000);
            return;
        }
        if (formData.newPassword !== formData.newPasswordRepeat) {
            setContent("Yeni şifre ile tekrarı uyuşmuyor");
            setTimeout(() => {
                setContent(null);
            }, 2000);
            return;
        }
        if (formData.newPassword === formData.currentPassword) {
            setContent("Yeni şifre, mevcut şifrenizle aynı olamaz");
            setTimeout(() => {
                setContent(null);
            }, 2000);
            return;
        }
        try {
            const res = await postUpdateUserPassword({
                userID: currentUser._id,
                newPassword: formData.newPassword
            });
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Profil" />
                </div>
                <div className='p-3'>
                    <div className='w-full bg-white p-4 mb-4 shadow-md overflow-auto rounded-md'>
                        {currentUser
                            ? <form
                                onSubmit={handleSubmit}
                                className='w-full flex flex-col md:flex-row'>
                                <div className='w-full md:w-80 mr-0 md:mr-5'>
                                    <div className='flex flex-col mb-6'>
                                        <label className='text-slate-600 font-medium mb-1'>Mevcut Şifre</label>
                                        <input
                                            name="currentPassword"
                                            type="password"
                                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                            onChange={handleChange}
                                            value={formData.currentPassword}
                                            required />
                                    </div>
                                    <div className='flex flex-col mb-6'>
                                        <label className='text-slate-600 font-medium mb-1'>Yeni Şifre</label>
                                        <input
                                            name="newPassword"
                                            type="password"
                                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                            onChange={handleChange}
                                            value={formData.newPassword}
                                            required />
                                    </div>
                                    <div className='flex flex-col mb-6'>
                                        <label className='text-slate-600 font-medium mb-1'>Yeni Şifre Tekrarı</label>
                                        <input
                                            name="newPasswordRepeat"
                                            type="password"
                                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                            onChange={handleChange}
                                            value={formData.newPasswordRepeat}
                                            required />
                                    </div>
                                    <button
                                        type="submit"
                                        className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500'>
                                        Güncelle
                                    </button>
                                </div>
                            </form>
                            : ""
                        }
                    </div>
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default ChangePassword;