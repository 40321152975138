import { useState } from 'react';
import tekbak from '../images/tek-bak.png';
import ViosynIot from '../images/viosyn-iot.png';
import { postLogin } from '../api/request';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const [content, setContent] = useState();
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postLogin(formData);
      if (res.data.message) {
        localStorage.setItem("viosyn", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        navigate('/');
      } else {
        setContent(res.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="bg-no-repeat bg-cover w-full h-screen bg-bg-mini min-[575px]:bg-bg">
      <div className='w-full flex justify-center h-screen items-center flex-col'>
        <img className='h-20 m-3' src={ViosynIot} alt="Viosyn IoT" />
        <form
          onSubmit={handleSubmit}
          className='flex justify-center items-center flex-col'>
          <input
            type="text"
            name="email"
            className='w-96 h-12 border border-gray-300 shadow-md rounded-full px-7 py-4 m-2
            text-sm text-left text-gray-700 outline-none backdrop-blur-sm bg-white/30'
            placeholder='E posta'
            onChange={handleChange}
            value={formData.email}
            required
          />
          <input
            type="password"
            name="password"
            className='w-96 h-12 border border-gray-300 shadow-md rounded-full px-7 py-4 m-2 
            text-sm text-left text-gray-700 outline-none backdrop-blur-sm bg-white/30'
            placeholder='Şifre'
            onChange={handleChange}
            value={formData.password}
            required
          />
          <div className='w-96 text-left pl-7 m-3 font-medium text-sm text-gray-600'>
            <Link to={"/reset-password"} >
              <p className='text-left'>Şifremi Unuttum</p>
            </Link>
          </div>
          {content
            ? <p className='text-sm font-medium text-red-500'> {content} </p>
            : ""
          }
          <button className='w-32 h-10 bg-gray-600 text-white m-5 rounded-full text-sm hover:bg-gray-700'>
            Giriş
          </button>
        </form>
      </div>
      <img className='absolute bottom-24 left-4 h-24' src={tekbak} alt="Tek Bak" />
    </div>
  );
}

export default Login;
