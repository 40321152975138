import axios from "axios";

const token = localStorage.getItem('viosyn');

// AnalysisData sayfasındaki url de ayarlanmalı
const instance = axios.create({
    baseURL: 'https://www.viosyn.com/api/',
    // baseURL: 'http://localhost:4000/api/',
    headers: { 'X-Custom-Header': 'foobar', 'Content-Type': 'application/json', 'authorization': `Bearer ${token}` }
})

export const makeRequest = async (type, path, body) => {
    try {
        const headers = {
            'authorization': `Bearer ${localStorage.getItem('viosyn')}`
        };
        const response = await instance[type](path, { ...body, headers });
        return response;
    } catch (error) {
        console.log(error.response);
        return error.response;
    }
}