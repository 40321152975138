import React, { useEffect, useState } from 'react';
import InfoBox from '../components/InfoBox';
import { getFindAuthority, postUpdateAuthority } from '../api/request';
import { useLocation } from 'react-router-dom';
import Title from '../components/Title';
import AuthorityForm from '../components/AuthorityForm';

function UpdateAuthority() {
    document.title = 'Yetki Güncelle';
    const location = useLocation();
    const authorityID = new URLSearchParams(location.search).get('authorityID') || "";

    const [content, setContent] = useState();
    const [formData, setFormData] = useState(null);

    const handleFormToggle = async (authority) => {
        if (authority.userID === "" || authority.gatewayID === "") {
            setContent("Lütfen tüm alanları doldurun.");
            setTimeout(() => {
                setContent(null);
            }, 2000);
            return;
        }
        try {
            authority._id = authorityID;
            const res = await postUpdateAuthority(authority);
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchAuthority = async () => {
            try {
                const res = await getFindAuthority(authorityID);
                setFormData(res.data.authority);
            } catch (error) {
                console.log(error);
            }
        };
        fetchAuthority();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Yetki Güncelle" />
                </div>
                <div className='p-3'>
                    {
                        formData && authorityID
                            ?
                            <div className='w-full bg-white p-4 mb-4 shadow-md rounded-md'>
                                <AuthorityForm
                                    buttonTitle="Güncelle"
                                    onToggle={handleFormToggle}
                                    authority={formData} />
                            </div>
                            : ""
                    }
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default UpdateAuthority;