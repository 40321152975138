import React, { useState } from 'react';
import DropdownMenu from '../components/DropdownMenu';
import { getIsEmailAvailable, getIsUsernameAvailable } from '../api/request';

function UserForm(props) {
    const [formData, setFormData] = useState(props.user);
    const currentData = props.user;
    const [emailContent, setEmailContent] = useState(null);
    const [usernameContent, setUsernameContent] = useState(null);

    const handleChange = (e) => {
        if (e.target.name === "phone") {
            if (/^\d*$/.test(e.target.value) && e.target.value.length <= 11) {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
            return;
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const isEmailAvailable = async () => {
        try {
            const res = await getIsEmailAvailable(formData.email);
            if (res.data.isEmailAvailable) {
                setEmailContent(res.data.content);
            } else {
                setEmailContent(null);
            }
            return res.data.isEmailAvailable;
        } catch (error) {
            console.log(error);
        }
    }

    const isUsernameAvailable = async () => {
        try {
            const res = await getIsUsernameAvailable(formData.username);
            if (res.data.isUsernameAvailable) {
                setUsernameContent(res.data.content);
            } else {
                setUsernameContent(null);
            }
            return res.data.isUsernameAvailable;
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!(/^[a-zA-Z0-9._-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/.test(formData.email))) {
            setEmailContent("E posta formatı hatalı");
            return;
        } else {
            setEmailContent(null);
        }
        var emailAvailable = formData.email === currentData.email ? false : await isEmailAvailable();
        var usernameAvailable = formData.username === currentData.username ? false : await isUsernameAvailable();
        if (!emailAvailable && !usernameAvailable) {
            props.onToggle(formData);
        }
    };

    const handleRoleToggle = (newRole) => {
        setFormData({ ...formData, role: newRole });
    };

    const handleIsActiveToggle = (newIsActive) => {
        setFormData({ ...formData, isActive: newIsActive });
    };

    return (
        <form
            onSubmit={handleSubmit}
            className='w-full flex flex-col'>
            <div className='w-full flex flex-col md:flex-row'>
                <div className='w-full md:w-80 mr-0 md:mr-5'>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Ad</label>
                        <input
                            type="text"
                            name="name"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.name}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Soyad</label>
                        <input
                            name="surname"
                            type="text"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.surname}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Rol</label>
                        <DropdownMenu
                            selection={formData.role}
                            onToggle={handleRoleToggle}
                            options={["Admin", "User"]} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Aktif</label>
                        <DropdownMenu
                            selection={formData.isActive}
                            onToggle={handleIsActiveToggle}
                            options={["Evet", "Hayır"]} />
                    </div>
                </div>
                <div className='w-full md:w-80 mr-0 md:mr-5'>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>
                            <p>
                                Kullanıcı Adı
                            </p>
                            {usernameContent
                                ? <p className='font-medium text-sm text-red-400 ml-1'>({usernameContent})</p>
                                : ""
                            }
                        </label>
                        <input
                            name="username"
                            type="text"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.username}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>
                            <p>
                                E posta
                            </p>
                            {emailContent
                                ? <p className='font-medium text-sm text-red-400 ml-1'>({emailContent})</p>
                                : ""
                            }
                        </label>
                        <input
                            name="email"
                            type="text"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.email}
                            required />

                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Cep Telefonu</label>
                        <input
                            name="phone"
                            type="text"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.phone}
                            required />
                    </div>
                </div>
            </div>
            <button
                type="submit"
                className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500 w-min'>
                {props.buttonTitle}
            </button>
        </form>
    )
}

export default UserForm;