import React, { useState, useEffect } from 'react';
import InfoBox from '../components/InfoBox';
import { getFindUser, postUpdateUser, postResetPassword } from '../api/request';
import { useLocation } from 'react-router-dom';
import UserForm from '../components/UserForm';
import Title from '../components/Title';

function UpdateUser() {
    document.title = 'Kullanıcı Güncelle';
    const location = useLocation();
    const userID = new URLSearchParams(location.search).get('userID') || "";

    const [content, setContent] = useState();
    const [formData, setFromData] = useState(null);

    const handleFormToggle = async (user) => {
        try {
            user._id = userID;
            const res = await postUpdateUser(user);
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await getFindUser(userID);
                if (res.data.message) {
                    setFromData(res.data.user)
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const resetPassword = async () => {
        try {
            const res = await postResetPassword(
                {
                    email: formData.email,
                    username: formData.username
                }
            );
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Kullanıcı Güncelle" />
                </div>
                <div className='p-3'>
                    {
                        formData && userID
                            ?
                            <div className='w-full bg-white p-4 mb-4 shadow-md overflow-auto rounded-md'>
                                <UserForm
                                    buttonTitle="Güncelle"
                                    onToggle={handleFormToggle}
                                    user={formData} />

                                <button
                                    onClick={resetPassword}
                                    className='bg-blue-400 text-white px-4 py-2 mt-5 rounded-lg hover:bg-blue-500 w-fit'>
                                    Şifre Gönder
                                </button>
                            </div>
                            : ""
                    }
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default UpdateUser;