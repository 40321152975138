import React, { useEffect, useState } from 'react';
import InfoBox from '../components/InfoBox';
import { getAllGateways, getAllPortTypes, postInsertPort } from '../api/request';
import Title from '../components/Title';
import DropdownMenu from '../components/DropdownMenu';

function AddPort() {
    document.title = 'Yeni Port';

    const [content, setContent] = useState();
    const [gatewaysName, setGatewaysName] = useState(null);
    const [gatewaysID, setGatewaysID] = useState(null);
    const [portTypesNames, setPortTypesNames] = useState(null);
    const [portTypesID, setPortTypesID] = useState(null);
    const [formData, setFormData] = useState({
        portNo: "",
        gateway: "",
        gatewayID: "",
        portType: "",
        portTypeID: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newPort = {
            portNo: formData.portNo,
            portTypeID: formData.portTypeID,
            gatewayID: formData.gatewayID,
        }
        if (formData.portNo === "" || formData.portTypeID === "" ||
            formData.gatewayID === "" || formData.companyID === "") {
            setContent("Lütfen tüm alanları doldurun.");
            setTimeout(() => {
                setContent(null);
            }, 2000);
            return;
        }
        try {
            const res = await postInsertPort(newPort);
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const fetchGateways = async () => {
            try {
                const res = await getAllGateways();
                const gatewaysName = res.data.gateways.map(item => item.name);
                const gatewaysID = res.data.gateways.map(item => item._id);
                setGatewaysName(gatewaysName);
                setGatewaysID(gatewaysID);
            } catch (error) {
                console.log(error);
            }
        };

        const fetchPortTypes = async () => {
            try {
                const res = await getAllPortTypes();
                const portTypesName = res.data.portTypes.map(item => item.name);
                const portTypesID = res.data.portTypes.map(item => item._id);
                setPortTypesNames(portTypesName);
                setPortTypesID(portTypesID);
            } catch (error) {
                console.log(error);
            }
        };

        fetchGateways();
        fetchPortTypes();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const selectedIndexNoGateway = (index) => {
        setFormData({ ...formData, gatewayID: gatewaysID[index] });
    };

    const selectedIndexNoPortType = (index) => {
        setFormData({ ...formData, portTypeID: portTypesID[index] });
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Yeni Port" />
                </div>
                <div className='p-3'>
                    <div className='w-full bg-white p-4 mb-4 shadow-md rounded-md'>
                        <form
                            onSubmit={handleSubmit}
                            className='w-full flex flex-col md:flex-row'>
                            <div className='w-full md:w-80 mr-0 md:mr-5'>
                                <div className='flex flex-col mb-6'>
                                    <label className='text-slate-600 font-medium mb-1'>Port No</label>
                                    <input
                                        type="text"
                                        name="portNo"
                                        className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                        onChange={handleChange}
                                        value={formData.portNo}
                                        required />
                                </div>
                                <div className='flex flex-col mb-6'>
                                    <label className='text-slate-600 font-medium mb-1'>Gateway</label>
                                    {
                                        gatewaysName
                                            ? <DropdownMenu
                                                selection={formData.gatewaysName}
                                                selectedIndexNo={selectedIndexNoGateway}
                                                options={gatewaysName} />
                                            : ""
                                    }
                                </div>
                                <div className='flex flex-col mb-6'>
                                    <label className='text-slate-600 font-medium mb-1'>Port Tipi</label>
                                    {
                                        portTypesNames
                                            ? <DropdownMenu
                                                selection={formData.portTypesNames}
                                                selectedIndexNo={selectedIndexNoPortType}
                                                options={portTypesNames} />
                                            : ""
                                    }
                                </div>
                                <button
                                    type="submit"
                                    className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500'>
                                    Kaydet
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default AddPort;