import React, { useEffect, useState } from 'react';
import InfoBox from '../components/InfoBox';
import { getSelectedPortSetting, postPortSetting } from '../api/request';
import Title from '../components/Title';
import DropdownMenu from '../components/DropdownMenu';
import { useNavigate, useParams } from 'react-router-dom';

function PortSetting() {
    document.title = 'Port Ayarları';

    const { portNo } = useParams();
    const [content, setContent] = useState();
    const [warning, setWarning] = useState();
    const [formData, setFormData] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newLimit = {
            portNo: portNo,
            userID: currentUser._id,
            isTopLimitActive: formData.isTopLimitActive,
            isDownLimitActive: formData.isDownLimitActive,
            topLimitYellowLevel: formData.isTopLimitActive ? convertValue(formData.topLimitYellowLevel) : null,
            topLimitRedLevel: formData.isTopLimitActive ? convertValue(formData.topLimitRedLevel) : null,
            downLimitYellowLevel: formData.isDownLimitActive ? convertValue(formData.downLimitYellowLevel) : null,
            downLimitRedLevel: formData.isDownLimitActive ? convertValue(formData.downLimitRedLevel) : null,
            isActive: formData.isActive,
        }

        if (!limitControl(newLimit)) {
            return;
        }

        try {
            const res = await postPortSetting(newLimit);
            setContent(res.data.content);
            setTimeout(() => {
                setContent(null);
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };

    const convertValue = (value) => {
        return value === "" || value === null ? null : Number(value);
    }

    const limitControl = (limit) => {
        if (!topLimitControl(limit)) {
            return false;
        }

        if (!downLimitControl(limit)) {
            return false;
        }

        if (!limitValueControl(limit)) {
            return false;
        }

        if (!notificationControl(limit)) {
            return false;
        }

        return true;
    }

    const topLimitControl = (limit) => {
        if (limit.isTopLimitActive) {
            if (limit.topLimitYellowLevel === null && limit.topLimitRedLevel === null) {
                setWarning("Üst limit değerleri tanımlı değil.");
                return false;
            }

            if (limit.topLimitYellowLevel !== null && limit.topLimitRedLevel !== null) {
                if (limit.topLimitYellowLevel > limit.topLimitRedLevel) {
                    setWarning("Üst limitteki sarı seviye kırmızı seviyeden fazla olamaz.");
                    return false;
                }
                if (limit.topLimitYellowLevel === limit.topLimitRedLevel) {
                    setWarning("Üst limitteki sarı seviye kırmızı seviyeye eşit olamaz.");
                    return false;
                }
            }
        }
        setWarning("");
        return true;
    }

    const downLimitControl = (limit) => {
        if (limit.isDownLimitActive) {
            if (limit.downLimitYellowLevel === null && limit.downLimitRedLevel === null) {
                setWarning("Alt limit değerleri tanımlı değil.");
                return false;
            }

            if (limit.downLimitYellowLevel !== null && limit.downLimitRedLevel !== null) {
                if (limit.downLimitYellowLevel < limit.downLimitRedLevel) {
                    setWarning("Alt limitteki sarı seviye kırmızı seviyeden az olamaz.");
                    return false;
                }
                if (limit.downLimitYellowLevel === limit.downLimitRedLevel) {
                    setWarning("Alt limitteki sarı seviye kırmızı seviyeye eşit olamaz.");
                    return false;
                }
            }
        }
        setWarning("");
        return true;
    }

    const limitValueControl = (limit) => {
        if (limit.isTopLimitActive && limit.isDownLimitActive) {
            if ((limit.topLimitYellowLevel !== null && limit.downLimitYellowLevel >= limit.topLimitYellowLevel) ||
                (limit.topLimitRedLevel !== null && limit.downLimitYellowLevel >= limit.topLimitRedLevel)) {
                setWarning("Alt limit üst limitte eşit veya fazla olamaz.");
                return false;
            }
            if ((limit.topLimitYellowLevel !== null && limit.downLimitRedLevel >= limit.topLimitYellowLevel) ||
                (limit.topLimitRedLevel !== null && limit.downLimitRedLevel >= limit.topLimitRedLevel)) {
                setWarning("Alt limit üst limitte eşit veya fazla olamaz.");
                return false;
            }
        }

        setWarning("");
        return true;
    }

    const notificationControl = (limit) => {
        if (limit.isActive === "Evet") {
            if (!limit.isTopLimitActive && !limit.isDownLimitActive) {
                setWarning("Bildirim almak için en az bir limit değeri tanımlı olmalı.");
                return false;
            }
        }
        setWarning("");
        return true;
    }

    useEffect(() => {
        const fetchPortSetting = async () => {
            try {
                const res = await getSelectedPortSetting(portNo, currentUser._id);
                if (!res.data.message && res.data.limit === null) {
                    navigate('/');
                    return;
                }
                if (res.data.message) {
                    setFormData(res.data.limit);
                } else {
                    setFormData({
                        isTopLimitActive: true,
                        isDownLimitActive: true,
                        topLimitYellowLevel: "",
                        topLimitRedLevel: "",
                        downLimitYellowLevel: "",
                        downLimitRedLevel: "",
                        isActive: "Evet",
                    })
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchPortSetting();
    }, [portNo, currentUser._id, navigate]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleIsActiveToggle = (newIsActive) => {
        setFormData({ ...formData, isActive: newIsActive });
    };

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Port Ayarları" />
                </div>
                <div className='p-3'>
                    {formData
                        ?
                        <div className='w-full bg-white p-4 mb-4 shadow-md rounded-md'>
                            <form
                                onSubmit={handleSubmit}
                                className='w-full flex flex-col md:flex-row'>
                                <div className='w-full md:w-80 mr-0 md:mr-5'>
                                    <div className='flex flex-row items-center'>
                                        <input
                                            className='w-5 h-5 mr-3'
                                            type='checkbox'
                                            checked={formData.isTopLimitActive}
                                            onChange={() => {
                                                setFormData({ ...formData, isTopLimitActive: !formData.isTopLimitActive });
                                            }} />
                                        <label className='text-slate-600 font-medium'>Üst Limit</label>
                                    </div>
                                    {
                                        formData.isTopLimitActive
                                            ?
                                            <div className="w-full flex flex-row mt-2">
                                                <div className='w-full flex flex-col mr-3'>
                                                    <label className='text-slate-600 font-medium mb-1'>Sarı Seviye</label>
                                                    <input
                                                        type="number"
                                                        name="topLimitYellowLevel"
                                                        className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                                            text-sm font-medium text-left text-gray-700 outline-none'
                                                        onChange={handleChange}
                                                        value={
                                                            formData.topLimitYellowLevel === null
                                                                ? ""
                                                                : formData.topLimitYellowLevel} />
                                                </div>
                                                <div className='w-full flex flex-col'>
                                                    <label className='text-slate-600 font-medium mb-1'>Kırmızı Seviye</label>
                                                    <input
                                                        type="number"
                                                        name="topLimitRedLevel"
                                                        className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                                            text-sm font-medium text-left text-gray-700 outline-none'
                                                        onChange={handleChange}
                                                        value={
                                                            formData.topLimitRedLevel === null
                                                                ? ""
                                                                : formData.topLimitRedLevel} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                    <div className='flex flex-row items-center mt-3'>
                                        <input
                                            className='w-5 h-5 mr-3'
                                            type='checkbox'
                                            checked={formData.isDownLimitActive}
                                            onChange={() => {
                                                setFormData({ ...formData, isDownLimitActive: !formData.isDownLimitActive });
                                            }} />
                                        <label className='text-slate-600 font-medium'>Alt Limit</label>
                                    </div>
                                    {
                                        formData.isDownLimitActive
                                            ?
                                            <div className="w-full flex flex-row mt-2">
                                                <div className='w-full flex flex-col mr-3'>
                                                    <label className='text-slate-600 font-medium mb-1'>Sarı Seviye</label>
                                                    <input
                                                        type="number"
                                                        name="downLimitYellowLevel"
                                                        className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                                            text-sm font-medium text-left text-gray-700 outline-none'
                                                        onChange={handleChange}
                                                        value={
                                                            formData.downLimitYellowLevel === null
                                                                ? ""
                                                                : formData.downLimitYellowLevel} />
                                                </div>
                                                <div className='w-full flex flex-col'>
                                                    <label className='text-slate-600 font-medium mb-1'>Kırmızı Seviye</label>
                                                    <input
                                                        type="number"
                                                        name="downLimitRedLevel"
                                                        className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                                            text-sm font-medium text-left text-gray-700 outline-none'
                                                        onChange={handleChange}
                                                        value={
                                                            formData.downLimitRedLevel === null
                                                                ? ""
                                                                : formData.downLimitRedLevel} />
                                                </div>
                                            </div>
                                            : ""
                                    }

                                    <div className='flex flex-col mb-6 mt-3'>
                                        <label className='text-slate-600 font-medium mb-1'>Bildirim</label>
                                        <DropdownMenu
                                            selection={formData.isActive}
                                            onToggle={handleIsActiveToggle}
                                            options={["Evet", "Hayır"]} />
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <button
                                            type="submit"
                                            className='bg-blue-400 text-white px-4 py-2 rounded-lg hover:bg-blue-500'>
                                            Kaydet
                                        </button>
                                        <p className='font-medium text-sm text-red-500 ml-2'>{warning}</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        : ""
                    }
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default PortSetting;