import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';

import AddGateway from './pages/AddGateway';
import Authorities from './pages/Authorities';
import AddAuthority from './pages/AddAuthority';
import AddCompany from './pages/AddCompany';
import AddPort from './pages/AddPort';
import AddUser from './pages/AddUser';
import Analysis from './pages/Analysis';
import Companies from './pages/Companies';
import Gateways from './pages/Gateways';
import Home from './pages/Home';
import Login from './pages/Login';
import Page404 from './pages/Page404';
import Ports from './pages/Ports';
import PortSetting from './pages/PortSetting';
import Profile from './pages/Profile';
import ResetPassword from './pages/ResetPassword';
import UpdateAuthority from './pages/UpdateAuthority';
import UpdateCompany from './pages/UpdateCompany';
import UpdateUser from './pages/UpdateUser';
import UpdateGateway from './pages/UpdateGateway';
import Users from './pages/Users';
import ChangePassword from './pages/ChangePassword';
import Navbar from './components/Navbar';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers/rootReducer'

const store = createStore(rootReducer);

const isAuthenticated = () => {
  const token = localStorage.getItem('viosyn');
  return token !== null;
};

const isAdmin = () => {
  const user = localStorage.getItem('user');
  const userRole = JSON.parse(user).role;
  return userRole === "Admin";
};

const isCurrentUser = () => {
  const user = localStorage.getItem('user');
  return user;
};

const PrivateRoute = ({ element, path }) => {
  const authLinks = [
    "/authorities",
    "/authorities/add-authority",
    "/authorities/update-authority",
    "/companies",
    "/companies/add-company",
    "/companies/update-company",
    "/users",
    "/users/add-user",
    "/users/update-user",
    "/gateways",
    "/gateways/add-gateway",
    "/gateways/update-gateway",
    "/ports",
    "/ports/add-port"
  ]

  if (!isAuthenticated() || !isCurrentUser()) {
    return <Navigate to="/login" replace state={{ from: path }} />
  }

  if (isAdmin()) {
    return (
      <div className='w-full h-full flex flex-row'>
        <div className='navbar-play-contain w-min fixed z-50 lg:relative'>
          <div className='h-screen sticky top-0'>
            <Navbar />
          </div>
        </div>
        <div className='w-full h-full pl-16 lg:pl-0 overflow-x-auto'>
          {element}
        </div>
      </div>
    )
  }

  const found = authLinks.find((element) => element === path);
  if (found !== undefined) {
    return <Navigate to="/" replace state={{ from: path }} />
  } else {
    return (
      <div className='w-full h-full flex flex-row'>
        <div className='navbar-play-contain w-min fixed z-50 lg:relative'>
          <div className='h-screen sticky top-0'>
            <Navbar />
          </div>
        </div>
        <div className='w-full h-full pl-16 lg:pl-0 overflow-x-auto'>
          {element}
        </div>
      </div>
    )
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/"
          element={<PrivateRoute element={<Home />} path="/" />}
        />
        <Route
          path="/analysis/:gatewayID"
          element={<PrivateRoute element={<Analysis />} path="/analysis/:gatewayID" />}
        />
        <Route
          path="/analysis/port-setting/:portNo"
          element={<PrivateRoute element={<PortSetting />} path="/analysis/port-setting/:portNo" />}
        />
        <Route
          path="/gateways"
          element={<PrivateRoute element={<Gateways />} path="/gateways" />}
        />
        <Route
          path="/gateways/add-gateway"
          element={<PrivateRoute element={<AddGateway />} path="/gateways/add-gateway" />}
        />
        <Route
          path="/gateways/update-gateway"
          element={<PrivateRoute element={<UpdateGateway />} path="/gateways/update-gateway" />}
        />
        <Route
          path="/profile"
          element={<PrivateRoute element={<Profile />} path="/profile" />}
        />
        <Route
          path="/ports"
          element={<PrivateRoute element={<Ports />} path="/ports" />}
        />
        <Route
          path="/ports/add-port"
          element={<PrivateRoute element={<AddPort />} path="/ports/add-port" />}
        />
        <Route
          path="/users"
          element={<PrivateRoute element={<Users />} path="/users" />}
        />
        <Route
          path="/users/add-user"
          element={<PrivateRoute element={<AddUser />} path="/users/add-user" />}
        />
        <Route
          path="/users/update-user"
          element={<PrivateRoute element={<UpdateUser />} path="/users/update-user" />}
        />
        <Route
          path="/authorities"
          element={<PrivateRoute element={<Authorities />} path="/authorities" />}
        />
        <Route
          path="/authorities/add-authority"
          element={<PrivateRoute element={<AddAuthority />} path="/authorities/add-authority" />}
        />
        <Route
          path="/authorities/update-authority"
          element={<PrivateRoute element={<UpdateAuthority />} path="/authorities/update-authority" />}
        />
        <Route
          path="/companies"
          element={<PrivateRoute element={<Companies />} path="/companies" />}
        />
        <Route
          path="/companies/add-company"
          element={<PrivateRoute element={<AddCompany />} path="/companies/add-company" />}
        />
        <Route
          path="/companies/update-company"
          element={<PrivateRoute element={<UpdateCompany />} path="/companies/update-company" />}
        />
        <Route
          path="/profile/change-password"
          element={<PrivateRoute element={<ChangePassword />} path="/profile/change-password" />}
        />
        <Route
          path="*"
          element={<PrivateRoute element={<Page404 />} path="*" />}
        />
      </Routes>
    </Provider>
  </BrowserRouter>
);
