const initialState = {
  isNavbarUpdate: true,
  currentPage: ""
}

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case "NAVBARUPDATE":
      return { isNavbarUpdate: action.value };
    case "CURRENTPAGE":
      return { currentPage: action.value };
    default:
      return state;
  }
}
export default rootReducer;