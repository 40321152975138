import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Title from '../components/Title';
import { getGatewayDatas } from '../api/request';
import DropdownMenu from '../components/DropdownMenu';
import AnalysisGraphic from '../components/AnalysisGraphic';
import AnalysisData from '../components/AnalysisData';
import { useDispatch } from 'react-redux';

function Analysis() {
    document.title = 'Analiz';
    const { gatewayID } = useParams();
    const [datas, setDatas] = useState(null);
    const [message, setMessage] = useState(true);
    const [dateOption, setDateOption] = useState(1);
    const [isGraphic, setIsGraphic] = useState(true);
    const [portNo, setPortNo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const dispatch = useDispatch();

    const startDateCalculate = (option) => {
        var currentDate = new Date();

        switch (option) {
            case 0:
                return new Date(currentDate.setHours(currentDate.getHours() - 5));
            case 1:
                return new Date(currentDate.setHours(currentDate.getHours() - 24));
            case 2:
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7);
            case 3:
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);
            case 4:
                return new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 90);
            default:
                return new Date(currentDate.setHours(currentDate.getHours() - 24));
        }
    }

    const endDateCalculate = () => {
        return new Date();
    }

    const selectedIndexNoDate = (index) => {
        setDateOption(index);
    };

    const fetchData = async (option) => {
        setIsLoading(true);
        try {
            const start = startDateCalculate(option);
            const end = endDateCalculate();
            const res = await getGatewayDatas(gatewayID, start, end, currentUser._id);
            setDatas(res.data.data);
            setMessage(res.data.message);
            setIsLoading(false);
            dispatch({ type: 'CURRENTPAGE', value: res.data.data[0].gatewayName });
        } catch (error) {
            console.log(error);
        }
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        scrollToTop();
        setDatas(null);
        fetchData(dateOption);
        const interval = setInterval(() => { fetchData(dateOption) }, 30000);
        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [dateOption]);

    useEffect(() => {
        setIsGraphic(true);
        scrollToTop();
        setDateOption(1);
        fetchData(1);
        // eslint-disable-next-line
    }, [gatewayID]);

    const options = ["Son 5 saat", "Son 1 gün", "Son 1 hafta", "Son 1 ay", "Son 3 ay"];

    const handleToggle = (status) => {
        scrollToTop();
        setIsGraphic(status);
    }

    const handlePortNo = (portNo) => {
        setPortNo(portNo);
    }

    return (
        <div>
            <div className="sticky top-0 bg-gray-100 z-30">
                <Title title="Analiz" />
            </div>
            {datas
                ?
                <div className='p-3'>
                    {
                        isGraphic
                            ? <div className='w-full h-14 flex justify-between bg-white mb-4 shadow-md rounded-md items-center py-2 px-5'>
                                <p className='font-medium'>{datas[0].gatewayName}</p>

                                <div className='w-36'>
                                    <DropdownMenu
                                        selection={options[dateOption]}
                                        selectedIndexNo={selectedIndexNoDate}
                                        options={options} />
                                </div>

                            </div>
                            : ""
                    }

                    {
                        isGraphic
                            ? <AnalysisGraphic
                                data={datas}
                                onToggle={handleToggle}
                                onPortNo={handlePortNo} />
                            : <AnalysisData
                                onToggle={handleToggle}
                                gatewayName={datas[0].gatewayName}
                                portNo={portNo} />
                    }

                </div>
                : !message && !isLoading
                    ? <div className='p-3'>
                        <div className='w-full h-14 flex justify-between bg-white mb-5 shadow-md rounded-md items-center py-2 px-5'>
                            <p className='font-medium'>Kayıtlı port yok</p>
                        </div>
                    </div>
                    : <div className='p-3'>
                        <div className='w-full h-14 flex justify-between bg-white mb-5 shadow-md rounded-md items-center py-2 px-5'>
                            <p className='font-medium'>Veriler Yükleniyor</p>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Analysis;