import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import { Link } from 'react-router-dom';
import { FaPlus, FaRegPenToSquare, FaRegTrashCan } from 'react-icons/fa6';
import { deleteAuthorities, getSelectedAuthorities } from '../api/request';
import InfoBox from '../components/InfoBox';
import { useDispatch } from 'react-redux';
import Search from '../components/Search';

function Authorities() {
    document.title = 'Yetki Listesi';
    const [authorities, setAuthorities] = useState(null);
    const [pageData, setPageData] = useState(null);
    const [content, setContent] = useState(null);
    const [search, setSearch] = useState("");
    const [selectedPageNo, setSelectedPageNo] = useState(1);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: "Yetki Listesi" });
    }, [dispatch]);

    useEffect(() => {
        const fetchGateways = async () => {
            try {
                const res = await getSelectedAuthorities(selectedPageNo, search);
                setAuthorities(res.data.authorities);
                setPageData({
                    pageNo: res.data.pageNo,
                    totalAuthorityCount: res.data.totalAuthorityCount,
                    totalPageCount: res.data.totalPageCount,
                    start: res.data.start,
                    limit: res.data.limit
                })
            } catch (error) {
                console.log(error);
            }
        };
        fetchGateways();
    }, [selectedPageNo, search]);

    const onToggleSearch = (searchKeyword) => {
        setSearch(searchKeyword);
        setSelectedPageNo(1);
    };

    const handleDeleteAuthorities = async (authorityID) => {
        const isConfirmed = window.confirm("Kayıt Silmek İstediğinize Emin misiniz?");
        if (isConfirmed) {
            try {
                const res = await deleteAuthorities(authorityID);
                setContent(res.data.content);
                if (res.data.message) {
                    setAuthorities((prevAuthorities) => prevAuthorities.filter(item => item._id !== authorityID));
                    dispatch({ type: 'NAVBARUPDATE', value: true });
                }
                setTimeout(() => {
                    setContent(null);
                }, 2000);
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <div>
            <div className={`w-full h-screen`}>
                <div className="sticky top-0 bg-gray-100 z-30">
                    <Title title="Yetki Listesi" />
                </div>
                <div className='p-3'>
                    <Search onToggle={onToggleSearch} />
                    <div className='overflow-auto'>
                        <table className="min-w-full shadow-md text-center text-sm font-light">
                            <thead
                                className="border-b bg-neutral-50 text-slate-600 font-medium">
                                <tr>
                                    <th scope="col" className='p-4'>
                                        <Link to='/authorities/add-authority' className='w-max h-max'>
                                            <div className='w-min h-full p-2 rounded-lg border-blue-400
                                                    border-2 text-blue-400
                                                    hover:cursor-pointer 
                                                    hover:bg-blue-300
                                                    hover:text-white'>
                                                <FaPlus className='w-4 h-4' />
                                            </div>
                                        </Link>
                                    </th>
                                    <th scope="col" className="px-6 py-4 text-left">Ad Soyad</th>
                                    <th scope="col" className="px-6 py-4 text-left">Firma</th>
                                    <th scope="col" className="px-6 py-4 text-left">Sil</th>
                                </tr>
                            </thead>
                            {authorities
                                ?
                                <tbody>
                                    {authorities.map((row, index) => (
                                        <tr className={`border-b ${index % 2 === 0 ? "bg-white" : "bg-zinc-100"} hover:bg-slate-100`} key={index}>
                                            <td className="w-5 px-6 py-4">
                                                <a href={`/authorities/update-authority?authorityID=${row._id}`}>
                                                    <FaRegPenToSquare className='w-4 h-4 hover:cursor-pointer text-emerald-400 hover:text-emerald-700' />
                                                </a>
                                            </td>
                                            <td className="px-6 py-4 text-left w-1/2">{row.userFullName}</td>
                                            <td className="px-6 py-4 text-left w-1/2">{row.companyName}</td>
                                            <td className="w-5 px-6 py-4">
                                                <FaRegTrashCan
                                                    className='w-4 h-4 hover:cursor-pointer text-red-400 hover:text-red-600'
                                                    onClick={() => handleDeleteAuthorities(row._id)} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                : ""}
                        </table>
                    </div>
                    {pageData && pageData?.totalAuthorityCount > 0
                        ?
                        <div className='w-full flex flex-row justify-between items-center pt-3 border-t border-slate-950'>
                            <div>
                                <p className="text-sm">
                                    {pageData.totalAuthorityCount} kayıttan {pageData.start + 1} - {pageData.start + authorities.length} arasındaki kayıtlar gösteriliyor
                                </p>
                            </div>
                            <div>
                                {
                                    <button
                                        className={`w-8 h-8 m-1 
                                        hover:bg-zinc-200 
                                        text-sm`}
                                        onClick={() => { setSelectedPageNo(1) }}>
                                        İlk
                                    </button>
                                }

                                {
                                    pageData.pageNo - 1 !== 0
                                        ?
                                        <button
                                            className={`w-8 h-8 m-1 
                                        ${pageData.pageNo === pageData.pageNo - 1 ? "bg-zinc-50 border border-slate-400 shadow-sm" : ""}  
                                        hover:bg-zinc-200 
                                        text-sm`}
                                            onClick={() => { setSelectedPageNo(pageData.pageNo - 1) }}>
                                            {Number(pageData.pageNo) - 1}
                                        </button>

                                        : ""
                                }

                                {
                                    <button
                                        className={`w-8 h-8 m-1 
                                        bg-zinc-50 border border-slate-400 shadow-sm
                                        hover:bg-zinc-200 
                                        text-sm`}
                                        onClick={() => { setSelectedPageNo(pageData.pageNo) }}>
                                        {pageData.pageNo}
                                    </button>
                                }

                                {
                                    Number(pageData.pageNo) + 1 !== pageData.totalPageCount + 1
                                        ?
                                        <button
                                            className={`w-8 h-8 m-1 
                                        ${pageData.pageNo === Number(pageData.pageNo) + 1 ? "bg-zinc-50 border border-slate-400 shadow-sm" : ""}  
                                        hover:bg-zinc-200 
                                        text-sm`}
                                            onClick={() => { setSelectedPageNo(Number(pageData.pageNo) + 1) }}>
                                            {Number(pageData.pageNo) + 1}
                                        </button>
                                        : ""
                                }

                                {
                                    <button
                                        className={`w-8 h-8 m-1 
                                        hover:bg-zinc-200 
                                        text-sm`}
                                        onClick={() => { setSelectedPageNo(pageData.totalPageCount) }}>
                                        Son
                                    </button>
                                }

                            </div>
                        </div>
                        : ""
                    }
                </div>
            </div>
            {content ? <InfoBox message={content} /> : ""}
        </div>
    )
}

export default Authorities;